<template>
  <b-sidebar
    id="add-partner-side-bar"
    :visible="isAddPartnerSideBarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-partner-side-bar-active', val)"
    @hidden="onHidden"
  >
    <template v-slot="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Синхронизировать контрагента
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-form-group label="Инн:">
            <div class="border-no-input">
              {{ element && element.taxNumber ? element.taxNumber : '' }}
            </div>
          </b-form-group>

          <b-form-group label="Название:">
            <div class="border-no-input">
              {{ element && element.name ? element.name : '' }}
            </div>
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name=" "
            rules="required"
          >
            <b-form-group
              label-for="searchPartner"
              label="Поиск товара:"
              :invalid-feedback="validationContext.errors[0]"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="searchPartner"
                :value="partner ? partner.id : null"
                :filterable="false"
                :options="partnerOptions"
                label="name"
                :reduce="val => val.id"
                placeholder="Выберите контрагента"

                @input="onInput"
                @open="onOpen"
                @close="onClose"
                @search="searchElement"
              >
                <template slot="no-options">
                  Введите для поиска контрагента
                </template>
                <template #open-indicator="{ attributes }">
                  <span
                    v-if="isLoading"
                    v-bind="attributes"
                  />
                </template>
                <template v-slot:spinner="{ loading }">
                  <b-spinner
                    v-show="loading || isLoading"
                    small
                    variant="primary"
                    label="Spinning"
                  />
                </template>

                <template #list-footer>
                  <li
                    v-show="hasNextPage"
                    ref="load"
                  >
                    <div class="text-center">
                      <b-spinner
                        variant="primary"
                        label="Spinning"
                        class="text-"
                      />
                    </div>
                  </li>
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>

          <div class="py-1">
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Применить
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Отмена
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BSidebar, BForm, BFormGroup, BSpinner, // BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, toRefs, computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ability from '@/libs/acl/ability'
import { partnerDataList, addPartnerFromCompany } from '@/application/partnerService'

localize('ru', ru)

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddPartnerSideBarActive',
    event: 'update:is-add-partner-side-bar-active',
  },
  props: {
    isAddPartnerSideBarActive: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      default: () => ({
        id: null,
        taxNumber: null,
        name: null,
      }),
    },
  },

  setup(props, { emit }) {
    const { element } = toRefs(props)
    const isLoading = ref(false)
    const load = ref(null)
    const partner = ref(null)
    const partnerOptions = ref([])

    const {
      refFormObserver,
      getValidationState,
      resetObserver,
    } = formValidation()

    const onSubmit = () => {
      addPartnerFromCompany(element.value.id, partner.value.id).then(result => {
        if (result) {
          emit('save')
          emit('update:is-add-partner-side-bar-active', false)
          emit('hidden')
        }
      })
    }

    const {
      fetchItems,
      totalItems,
      currentPage,
      searchQuery,
    } = partnerDataList()

    const onHidden = () => {
      partner.value = null
      partnerOptions.value = []
      searchQuery.value = ''
      currentPage.value = 1
      totalItems.value = 0
      resetObserver()
      emit('hidden')
    }

    const hasNextPage = computed(() => partnerOptions.value.length < totalItems.value)

    const searchElement = async (query, loading) => {
      if (query) {
        searchQuery.value = query
        currentPage.value = 1
        totalItems.value = 0

        fetchItems(null, items => {
          partnerOptions.value = items
          loading(false)
        })
      }
    }

    const onInput = partnerId => {
      partner.value = partnerOptions.value.find(x => String(x.id) === String(partnerId))
      searchQuery.value = ''
    }

    return {
      ability,
      isLoading,
      load,
      partner,
      partnerOptions,
      hasNextPage,

      onSubmit,
      onHidden,

      required,
      refFormObserver,
      getValidationState,

      fetchItems,
      totalItems,
      currentPage,

      searchElement,
      onInput,
    }
  },
  data: () => ({
    observer: null,
  }),
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      this.currentPage = 1
      this.partnerOptions = []
      this.fetchItems(null, async items => {
        this.partnerOptions = items
        await this.$nextTick()
        if (this.hasNextPage) {
          this.observer.observe(this.$refs.load)
        }
      })
    },
    async onClose() {
      if (this.partner) {
        this.partnerOptions = [...new Set([...this.partnerOptions, this.partner])]
      }
      this.searchQuery = ''
      this.currentPage = 1
      this.observer.disconnect()
      await this.$nextTick()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.currentPage += 1
        this.fetchItems(null, async items => {
          this.partnerOptions.push(...items)
          await this.$nextTick()
          ul.scrollTop = scrollTop
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
