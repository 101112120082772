import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/partner', { params })).data
    if (error) throw error

    return result
  },
  async addPartner(companyId, partnerId) {
    const { error, result } = (await axiosIns.put(`/partner/${partnerId}`, { companyId })).data
    if (error) throw error

    return result
  },
  async clearPartner(companyId, partnerId) {
    const { error, result } = (await axiosIns.delete(`/partner/${partnerId}`, { data: { companyId } })).data
    if (error) throw error

    return result
  },
}
