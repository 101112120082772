<template>
  <b-row>
    <b-col cols="12">
      <!-- боковая панель чека -->
      <add-partner-side-bar
        :is-add-partner-side-bar-active.sync="isAddPartnerSideBarActive"
        :element="editCompany"
        @save="refetchData"
        @hidden="editCompany = null"
      />
      <!-- *** -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refApplicationForm"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="Columns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(createdAt)="data">
            {{ formatDate(data.value) }}
          </template>

          <template #cell(id)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <span>{{ data.value }}</span>
              </template>

              <b-dropdown-item
                v-if="!data.item.partnerCode"
                @click="onEditCompany(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">
                  Прикрепить контрагента
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.partnerCode"
                @click="removePartnerFromCompany(data.item.id, data.item.partnerId, refetchData)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">
                  Открепить контрагента
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BPagination,

} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { formatDate } from '@/libs/helper'
import getElementList from '@/application/companyService'
import { removePartnerFromCompany } from '@/application/partnerService'
import AddPartnerSideBar from './components/AddPartnerSideBar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    AddPartnerSideBar,
  },

  setup() {
    const isAddPartnerSideBarActive = ref(false)
    const editCompany = ref(null)
    const {
      refApplicationForm,
      dataMeta,
      fetchItems,
      Columns,
      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,

    } = getElementList()

    function onEditCompany(item) {
      const { id, taxNumber, name } = item
      editCompany.value = { id, taxNumber, name }
      isAddPartnerSideBarActive.value = true
    }

    return {
      isAddPartnerSideBarActive,
      refApplicationForm,
      Columns,
      dataMeta,
      fetchItems,

      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      formatDate,

      editCompany,
      onEditCompany,

      removePartnerFromCompany,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
