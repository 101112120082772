import partnerRepository from '@/repository/partnerRepository'
import { ref } from '@vue/composition-api'
import store from '@/store'
import ability from '@/libs/acl/ability'
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'

export function partnerDataList() {
  const isLoading = ref(false)
  const perPage = ref(20)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)

  const fetchItems = (ctx, callback) => {
    partnerRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }
        totalItems.value = count
        isLoading.value = false
        callback(items)
      })
      .catch(error => {
        isLoading.value = false
        store.commit('app/SET_ERROR', error)
      })
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    searchQuery,
    sortBy,
    isSortDirDesc,
    isLoading,
  }
}

export const addPartnerFromCompany = async (companyId, partnerId) => {
  try {
    await partnerRepository.addPartner(companyId, partnerId)
    return true
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return false
  }
}

export const removePartnerFromCompany = async (companyId, partnerId, refetchData) => {
  if (!ability.can('read', 'UserManage')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для открепления')
  }
  const result = await Swal.fire({
    title: 'Открепить контрагента!',
    text: 'Вы уверены в необходимости открепить контрагента?',
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'Отмена',
    confirmButtonText: 'Подтвердить',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-primary ml-1',
    },
    buttonsStyling: false,
  })

  if (result.value) {
    partnerRepository.clearPartner(companyId, partnerId).then(() => {
      store.commit('app/SET_MESSAGE', 'Выполнено успешно!')
      if (refetchData) {
        refetchData()
      }
    }).catch(error => {
      store.commit('app/SET_ERROR', error)
    })
  }
}
