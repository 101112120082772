var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-partner-side-bar","visible":_vm.isAddPartnerSideBarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-add-partner-side-bar-active', val); },"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Синхронизировать контрагента ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":"Инн:"}},[_c('div',{staticClass:"border-no-input"},[_vm._v(" "+_vm._s(_vm.element && _vm.element.taxNumber ? _vm.element.taxNumber : '')+" ")])]),_c('b-form-group',{attrs:{"label":"Название:"}},[_c('div',{staticClass:"border-no-input"},[_vm._v(" "+_vm._s(_vm.element && _vm.element.name ? _vm.element.name : '')+" ")])]),_c('validation-provider',{attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"searchPartner","label":"Поиск товара:","invalid-feedback":validationContext.errors[0],"state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"id":"searchPartner","value":_vm.partner ? _vm.partner.id : null,"filterable":false,"options":_vm.partnerOptions,"label":"name","reduce":function (val) { return val.id; },"placeholder":"Выберите контрагента"},on:{"input":_vm.onInput,"open":_vm.onOpen,"close":_vm.onClose,"search":_vm.searchElement},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [(_vm.isLoading)?_c('span',_vm._b({},'span',attributes,false)):_vm._e()]}},{key:"spinner",fn:function(ref){
var loading = ref.loading;
return [_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(loading || _vm.isLoading),expression:"loading || isLoading"}],attrs:{"small":"","variant":"primary","label":"Spinning"}})]}},{key:"list-footer",fn:function(){return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNextPage),expression:"hasNextPage"}],ref:"load"},[_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"text-",attrs:{"variant":"primary","label":"Spinning"}})],1)])]},proxy:true}],null,true)},[_c('template',{slot:"no-options"},[_vm._v(" Введите для поиска контрагента ")])],2)],1)]}}],null,true)}),_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Применить ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Отмена ")])],1)])],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }