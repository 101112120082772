import companyRepository from '@/repository/companyRepository'

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import ability from '@/libs/acl/ability'

export default function getElementList() {
  const Columns = [
    { key: 'id', label: 'Id', sortable: true },
    { key: 'taxNumber', label: 'Инн' },
    { key: 'name', label: 'Название', sortable: true },
    { key: 'address', label: 'Адрес', sortable: false },
    { key: 'partnerCode', label: 'Код 1С', sortable: false },
    { key: 'partnerName', label: 'Контрагент', sortable: false },
    { key: 'managerClient', label: 'Менеджер', sortable: false },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
  ]

  const refApplicationForm = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refApplicationForm.value ? refApplicationForm.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'UserManage')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refApplicationForm.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    companyRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refApplicationForm,
    Columns,

    refetchData,

  }
}
